import { Node } from "@craftjs/core"
import { CSSProperties } from "react"
import { Border } from "../types/main"
import { useMediaQuery } from "@mui/material"
import axios from "axios"

export const request = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL
})

export const getRgbaParts = (color: string) => {
  return color.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i)
}

export const getBorder = (border: Border) => {
  if (border && border.sides && border.sides.length !== 0) {
    let style = `${border.width || '3px'} ${border.style || 'solid'} ${border.color || '#000000'}`

    if (border.sides.includes('full')) {
      return { border: style }
    } else {
      let borders: any = {}

      border.sides.forEach(side => borders[side] = style)

      return borders
    }
  }

  return {}
}

export const getStyle = (style: CSSProperties, mobileStyle: CSSProperties, parentNode: Node, mobile: boolean): CSSProperties => {
  let combined_style = {
    transform: 'scaleX(1) scaleY(1)',
    ...style
  }

  if (mobile) {
    let multiplier = 36 / (parentNode.data.name === 'Section' ? 36 : parentNode.data.props.grids)

    if (mobileStyle) {
      combined_style = { ...combined_style, ...mobileStyle }

      if (!mobileStyle.gridColumnStart) {
        combined_style.gridColumnStart = (parseInt(style.gridColumnStart + '') - 1) * multiplier + 1
      }
    } else if (parentNode.data.name === 'Column') {
      let end = combined_style.gridColumnEnd as string
      combined_style = {
        ...combined_style,
        gridColumnStart: (parseInt(style.gridColumnStart + '') - 1) * multiplier + 1,
        gridColumnEnd: 'span ' + parseInt(end.replace('span ', '')) * multiplier
      }
    }
  }

  return combined_style
}

export const getDesign = (design_id: string, campaign_id: string) => {
  return request.get(process.env.REACT_APP_API_URL + `editor/design/${design_id}/campaign/${campaign_id}/get`)
}

export const getDomainDesign = (domain: string) => {
  return axios.create({
      withCredentials: true
    }).get(`https://staging2-admin.directmail.io/api/landing_page/state/${domain}`)
}

export const startSession = (path: string, token: string, campaign_id?: string) => {
  return axios.create({
      withCredentials: true
    }).post(`https://staging2-admin.directmail.io/api/landing_page/index${path}`, {})
}

export const submitForm = (model: any) => {
  return axios.create({
    withCredentials: true
  }).post(`https://staging2-admin.directmail.io/api/landing_page/form/update`, model)
}

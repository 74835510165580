import React, { useEffect, useState } from 'react'

import { Frame, useEditor } from '@craftjs/core'
import { DesignInterface } from '../../types/main'
import { getDesign, getDomainDesign, startSession } from '../../services/HelperService'
import { RecipientProvider } from '../../contexts/RecipientContext'

export const Renderer = () => {
  const [variables, setVariables] = useState(null)
  const [recipient, setRecipient] = useState(null)
  const [token, setToken] = useState(null)
  const {actions: {history}} = useEditor()
  const queryParams = new URLSearchParams(window.location.search)

  useEffect(() => {
    const host = window.location.hostname
    const path = window.location.pathname === '/' ? '' : window.location.pathname
    const design_id = queryParams.get('design')
    const campaign_id = queryParams.get('campaign_id')
    const defaultVariables = {
      first_name: 'Customer',
      street: 'Street',
      apt: 'Apt/Suite',
      city: 'City',
      state: 'State',
      zip: 'Zip',
      zip_plus_four: 'XXXX',
      auto_make: 'Make',
      auto_model: 'Model',
      trade_in_value: '$0',
      pre_approval_amnt: '$0'
    }
    // host = 'www.thisisatestingdomain.com'
    // path = path + '?domain=www.thisisatestingdomain.com'

    if (host.includes('admin.directmail.io')) {
      if (design_id) {
        getDesign(design_id, campaign_id)
          .then((response) => {
            if (response.data.status == 'success') {
              const design: DesignInterface = response.data.data   
              history.ignore().deserialize(JSON.parse(design.state))	
              setToken(design.token)

              startSession('', design.token, campaign_id)
                .then((response) => {
                  const data = response.data.data
                  const variables = {
                    ...defaultVariables,
                    ...(data.variables || {})
                  }
                  let recipient: any = {}
      
                  if (data.recipient || data.contact) {
                    recipient = data.contact || data.recipient
                    recipient.initialOptions = data.initialOptions
                  }
      
                  if (recipient?.phone?.length) {
                    const filtered = recipient?.phone.filter((item: any) => item.is_main === 1)
      
                    if (filtered[0]) {
                      recipient.phone = filtered[0].phone_number
                    }
                  }
                  
                  if (data.jumpToRedemption) {
                    recipient = {
                      ...recipient,
                      redemption: data.redemptionOptions
                    }
                  }
      
                  setRecipient(recipient)
                  setVariables(variables)
                })
            }
          })

        setRecipient({})
        setVariables(defaultVariables)
      }
    } else {
      getDomainDesign(host)
        .then((response) => {
          if (response.data.status == 'success') {
            const design: DesignInterface = response.data.data   
            history.ignore().deserialize(JSON.parse(design.state))	

            startSession(path, design.token)
              .then((response) => {
                const data = response.data.data
                const variables = {
                  ...defaultVariables,
                  ...(data.variables || {})
                }
                let recipient: any = {}
    
                if (data.recipient || data.contact) {
                  recipient = data.contact || data.recipient
                  recipient.initialOptions = data.initialOptions
                }
    
                if (recipient?.phone?.length) {
                  const filtered = recipient?.phone.filter((item: any) => item.is_main === 1)
    
                  if (filtered[0]) {
                    recipient.phone = filtered[0].phone_number
                  }
                }
                
                if (data.jumpToRedemption) {
                  recipient = {
                    ...recipient,
                    redemption: data.redemptionOptions
                  }
                }

                setToken(data.token)
                setRecipient(recipient)
                setVariables(variables)
              })
          }
        })
    }
  }, [])

  return <RecipientProvider value={[recipient, token, setRecipient, variables]}>
    <Frame></Frame>
  </RecipientProvider>
}